import Vue from 'vue'
import axios from 'axios'

let typeList = []
let timestamp = new Date().getTime()
axios.get('https://foss.jinqiruanjian.com/poverty/config/type-list.json?' + timestamp).then((response) => {
  typeList = response.data
})
export default {
  statusFn (s, m) {
    switch (s) {
      case 401:
        if (m === '登录') {
          Vue.prototype.$toast('用户名或密码错误')
        } else {
          Vue.prototype.$toast('登录超时请重新登录')
        }

        break
      case 403:
        Vue.prototype.$toast('暂无' + m + '权限')
        break
      case 502:
        Vue.prototype.$toast('服务器正在维护中，请稍后再试')
        break
      case 503:
        Vue.prototype.$toast('服务器正在维护中，请稍后再试')
        break
      case 507:
        Vue.prototype.$toast(m + '失败')
        break
      default:
        Vue.prototype.$toast(m + '失败')
    }
  },
  buttonShowFn (code) {
    let users = JSON.parse(sessionStorage.getItem('users'))
    if (users) {
      if (String(users.authorities).indexOf(code) !== -1) {
        return true
      } else {
        return false
      }
    }
  },
  gettypeListFn (e) {
    return typeList[e]
  },
  getTableStyleObj () {
    let result = {
      rowStyle: { height: '10px' },
      headerRowStyle: { height: '10px' },
      cellStyle: { padding: '7px 0 5px 0' },
      headerCellStyle: { padding: '5px 0' }
    }
    return result
  },
  gettypeListLabelFn (e, e1) {
    if (e1 === '' || e1 === null || e1 === undefined) {
      return ''
    } else {
      if (String(e1).indexOf(',') !== -1) {
        let objArr = e1.split(',')
        let objArrret = []
        for (let i = 0; i < typeList[e].length; i++) {
          for (let j = 0; j < objArr.length; j++) {
            if (objArr[j] === typeList[e][i].value) {
              objArrret.push(typeList[e][i].text)
            }
          }
        }
        return objArrret.join(',')
      } else {
        for (let i = 0; i < typeList[e].length; i++) {
          if (e1 === typeList[e][i].value) {
            return typeList[e][i].text
          }
        }
      }
    }
  },
  // 导出
  exportsFn (name, type, data) {
    var blob = new Blob([data])
    var link = document.createElement('a')
    link.download = name + '.' + type
    link.href = URL.createObjectURL(blob)
    link.click()
  }
}
