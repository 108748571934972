import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Create from '../views/create.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/basic',
    name: 'basic',
    component: () => import('../views/basic/index.vue')
  },
  {
    path: '/familyMember',
    name: 'familyMember',
    component: () => import('../views/familyMember/index.vue')
  },
  {
    path: '/familyMemberDetails',
    name: 'familyMemberDetails',
    component: () => import('../views/familyMember/details.vue')
  }
  ,
  {
    path: '/income',
    name: 'income',
    component: () => import('../views/income/index.vue')
  }
  ,
  {
    path: '/guarantees',
    name: 'guarantees',
    component: () => import('../views/guarantees/index.vue')
  }
  ,
  {
    path: '/responsible',
    name: 'responsible',
    component: () => import('../views/responsible/index.vue')
  }
  ,
  {
    path: '/measure',
    name: 'measure',
    component: () => import('../views/measure/index.vue')
  },
  {
    path: '/measureDetails',
    name: 'measureDetails',
    component: () => import('../views/measure/details.vue')
  },
  {
    path: '/opinion',
    name: 'opinion',
    component: () => import('../views/opinion/index.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/index.vue')
  },
  {
    path: '/:content',
    name: 'create',
    component: Create
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
