import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import common from '@/assets/js/common'

Vue.prototype.common = common
Vue.use(Vant);

Vue.config.productionTip = false



// 全局路由守卫
router.beforeEach((to, from, next) => {

  let token = sessionStorage.getItem('token')
  if (from.path === '/login') {
    if (!token && to.path !== '/login') {
      if (to.path === '/income') {
        next({ path: '/login' })
      } else {
        next()
      }
    } else {
      next()
    }
  } else {
    next()
  }

})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
