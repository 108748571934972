<template>
  <div class="home">
    <div class="householdCodeType">
      <div class="codeNum">户编码: {{ postForm.householderCode }}</div>
      <div class="type">
        {{ postForm.householdCategory }}
      </div>
    </div>
    <div class="householdName_member">
      <div class="householdName">
        <i><img src="@/assets/img/father.png" alt="" /></i>&nbsp;
        <span>户主: {{ postForm.name }}</span>
      </div>
      <div class="householdMember">
        <i><img src="@/assets/img/family.png" alt="" /></i>&nbsp;
        <span>家庭成员: {{ postForm.populationNumber }}人</span>
      </div>
    </div>
    <div class="causeBox">
      <div class="causeTitle">致贫/返贫风险:</div>
      <div class="cause">
         {{ postForm.riskType }}
      </div>
    </div>
    <div class="addressBox">
      <div class="addressTitle">家庭住址:</div>
      <div class="address">{{ postForm.address }}</div>
    </div>
    <div class="informBox">
      <div class="informBoxTop">
        <span class="new"> 最新 </span>
        <div class="inform">通知</div>
      </div>
      <div class="informContent">
        <span> 您的帮扶申请村集体已审核通过，已提交乡镇审核。 </span>
        <br />
        <span> 您的帮扶申请乡镇已审核通过，已提交县乡村振兴局。 </span>
      </div>
    </div>
    <ul class="serveBox">
      <li class="serve" v-for="(v, i) in serveArr" :key="i" @click="goto(v)">
        <i><img :src="v.icon" alt="" /></i>
        <span class="serveTitle">{{ v.title }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      postForm: {
        householderCode: "",
        householdCategory: "",
        name: "",
        populationNumber: "",
        riskType: "",
        address: "",
      },
      serveArr: [
        {
          title: "基本情况",
          icon: require("@/assets/img/home.png"),
          url: "/basic",
        },
        {
          title: "家庭成员",
          icon: require("@/assets/img/family.png"),
          url: "/familyMember",
        },
        {
          title: "家庭收支情况",
          icon: require("@/assets/img/income.png"),
          url: "/income",
        },
        {
          title: "三保障和饮水安全",
          icon: require("@/assets/img/cereals.png"),
          url: "/guarantees",
        },
        {
          title: "帮扶责任人",
          icon: require("@/assets/img/pinkypromise.png"),
          url: "/responsible",
        },
        {
          title: "帮扶措施",
          icon: require("@/assets/img/bill.png"),
          url: "/measure",
        },
      ],
    };
  },
  created() {
    this.postForm = JSON.parse(sessionStorage.getItem("postForm"))
    let token = sessionStorage.getItem("token")
    if (!token) {
      if (this.postForm.name.length == 3) {
        this.postForm.name =
          this.postForm.name.substring(0, 1) +
          "*" +
          this.postForm.name.substring(
            this.postForm.name.length - 1,
            this.postForm.name.length
          );
      } else if (this.postForm.name.length == 2) {
        this.postForm.name = this.postForm.name.substring(0, 1) + "*";
      }
    }
  },
  mounted() {
    if (this.postForm.householdCategory === '01') {
      this.postForm.householdCategory = '一般户'
    } else if (this.postForm.householdCategory === '02') {
      this.postForm.householdCategory = '脱贫享受政策户'
    } else if (this.postForm.householdCategory === '03') {
      this.postForm.householdCategory = '脱贫不享受政策户'
    } else if (this.postForm.householdCategory === '04') {
      this.postForm.householdCategory = '监测对象户'
    }
    if (this.postForm.riskType === "01") {
      this.postForm.riskType = "因病";
    } else if (this.postForm.riskType === "02") {
      this.postForm.riskType = "因学";
    } else if (this.postForm.riskType === "03") {
      this.postForm.riskType = "因残";
    } else if (this.postForm.riskType === "04") {
      this.postForm.riskType = "因自然灾害";
    } else if (this.postForm.riskType === "05") {
      this.postForm.riskType = "因意外事故";
    } else if (this.postForm.riskType === "06") {
      this.postForm.riskType = "因产业项目失败";
    } else if (this.postForm.riskType === "07") {
      this.postForm.riskType = "因务工就业";
    } else if (this.postForm.riskType === "08") {
      this.postForm.riskType = "缺劳动力";
    } else if (this.postForm.riskType === "09") {
      this.postForm.riskType = "其他";
    } else if (this.postForm.riskType === "10") {
      this.postForm.riskType = "因安全住房";
    } else if (this.postForm.riskType === "11") {
      this.postForm.riskType = "因安全饮水";
    } else {
      this.postForm.riskType = "";
    }
  },
  methods: {
    goto(v) {
      let getToken = sessionStorage.getItem("token");
      if (getToken === null) {
        console.log(v.url);
        if (
          v.url === "/income" ||
          v.url === "/measure" ||
          v.url === "/measureDetails"
        ) {
          this.$router.push("/login");
        } else {
          this.$router.push(v.url);
        }
      } else {
        this.$router.push(v.url);
      }
    },
  },
};
</script>
<style scoped>
.home {
  width: 100%;
}
.householdCodeType,
.householdName_member,
.causeBox,
.addressBox {
  width: 94%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  height: 0.5rem;
  border-bottom: 1px solid #eee;
  padding-top: 0.13rem;
  box-sizing: border-box;
}
.householdCodeType > .codeNum {
  height: 0.2rem;
}
.householdCodeType > .type {
  height: 0.2rem;
  color: #fff;
  background-color: #00bfff;
  padding: 0.02rem 0.2rem;
  border-radius: 0.15rem;
}
.householdName_member {
  padding-top: 0.13rem;
}
.householdName_member i {
  display: inline-block;
  width: 0.25rem;
  height: 0.25rem;
}
.householdName_member img {
  width: 100%;
  height: 100%;
  vertical-align: middle;
}
.causeTitle {
  width: 35%;
}
.cause {
  width: 65%;
  text-align: center;
}
.addressTitle {
  width: 20%;
}
.address {
  width: 80%;
  text-align: right;
  font-size: 0.13rem;
}
.informBox {
  width: 94%;
  height: 0.8rem;
  background-color: #eee;
  border-radius: 0.35rem;
  margin: 0.08rem auto;
  padding: 0.05rem 0;
  box-sizing: border-box;
}
.informBoxTop {
  margin-left: 0.2rem;
}
.inform {
  font-size: 0.12rem;
  display: inline-block;
  background-color: red;
  color: #fff;
  padding: 0.02rem 0.05rem;
  border-radius: 0.04rem;
}
.new {
  font-weight: bold;
}
.informContent {
  text-align: center;
  font-size: 0.12rem;
  color: #555;
}
.informContent > span {
  margin-top: 0.05rem;
  display: inline-block;
}
.serve > i {
  display: inline-block;
  width: 0.4rem;
  height: 0.4rem;
}
.serve > i > img {
  width: 100%;
  height: 100%;
}
.serveTitle {
  display: inline-block;
  width: 100%;
  margin-top: 0.05rem;
}
.serveBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: center;
  border-top: 1px solid #eee;
}
.serve {
  width: calc(100% / 3);
  height: 0.9rem;
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
  box-sizing: border-box;
  padding-top: 0.1rem;
}
</style>
