<template>
  <div></div>
</template>

<script>
export default {
  name: "",
  components: {},
  data () {
    return {
      postForm: {}
    };
  },
  mounted () {
    let Base64 = require('js-base64').Base64;
    if (this.postForm === {}) {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
    }
    this.postForm = Base64.decode(this.$route.params.content.replaceAll('^jqrj^','/'))
    sessionStorage.setItem('postForm', this.postForm)
    // this.$store.commit('stateGet', JSON.parse(Base64.decode(this.$route.params.content)))
    this.$nextTick(() => {
      this.$router.push('/home')
    })
  },
  watch: {
  },
  methods: {

  },
};
</script>

<style>
</style>